.nav-span{
    position: relative;
    cursor: pointer;
    font-size: 100%;
    color: black;
    font-weight: bold;
    background-color:transparent;
    width:30vh;
    display: table-cell;
    vertical-align: middle;
    height: 40px;
    border-right: 0.05px solid #D1D3D4;
    border-left: 0.05px solid #D1D3D4 ;
    text-align: center;
}

.nav-span-large{
    background-color:transparent;;
    display: table-cell;
    vertical-align: middle;
    height: 40px;
    width:30vh;
    border-right: 0.05px solid #D1D3D4 ;
    border-left: 0.05px solid #D1D3D4 ;
    text-align: center !important;
    cursor: pointer;
    font-size: 110%;
    color: black;
    font-weight: bold;
}

.nav-span:hover,.nav-span-large:hover,a:hover{
    background-color: #0075B2;
    color: white;
}

a#active{
    cursor: pointer;
    color:white;
    font-size: 100%;
    background-color:#0075B2;;
    height:40px;
    text-align: center;
}
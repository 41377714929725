
.important-messages-section{
    padding: 10px;
}

.fr-res-title{
    font-weight: bold;
    font-size: 40px;
}

#message-tes-title{
    font-weight: bold;
    font-size: 40px;
    text-align: center;
    
}

.view-all-button{
    color: white;
    background-color: rgb(35,31,32);
    border: none;
    border-color: transparent;
    height: 40px;
    width: 150px;
    cursor: pointer;
}

.messages-div{
    margin: 10px 0 0 0px; 
    align-items: center;
    justify-content: center
}

@media only screen and (max-width: 600px){
    
    #message-tes-title{
        font-weight: bold;
        font-size: 26px;
        text-align: center;
    }

    .fr-res-title{
        font-weight: bold;
        font-size: 26px;
    }
}

.ScrollMenu{
    display: flex;
    justify-content: center;
}


.messages-div{
    margin: 10px 0 0 10px; 
    align-items: center;
    justify-content: center
}

.important-messages-section{
    padding: 5px;
}

#message-text-title{
    font-weight: bold;
    font-size: 40px;
    text-align: center;
}

.fr-trav-title{
    font-weight: bold;
    font-size: 40px;
}

.view-all-button{
    color: white;
    background-color: rgb(35,31,32);
    border: none;
    border-color: transparent;
    height: 40px;
    width: 150px;
    cursor: pointer;
}

.ant-pagination-item-active a {
    color: rgb(223,143,47);
}

.ant-pagination-item-active {
	background: #fff;
	border-color: rgb(223,143,47);
}

@media only screen and (max-width: 600px){
    .fr-trav-title{
        font-weight: bold;
        font-size: 26px;
    }
}



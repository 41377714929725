.popover-inner {
    width: 200px !important;
    max-width:200px !important;
}
 
.popover {
    width: 200px !important;
    max-width:200px !important;
}

#title{
    font-size:40px;
    font-weight: bolder;
    color: white;
}

.fraud-txt{
    position: absolute;
    top:10%;
    color: white;
    text-align: center;
    padding:0%;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
}

#title-mobile{
    font-size:26px;
    font-weight: bolder;
    color: white;
}

.mobile-fraud-txt{
    position: absolute;
    top:5%;
    color: white;
    text-align: center;
    padding:10%;
    font-size: 16px;
    font-weight: bold;
    width: 100%;
 }

 .operation{
    width:100%;
    color:rgb(255,255,255);
    font-size:14px;
    padding: 20px;
}

.fraudSection{
    height: 250px;
    position: relative;
    display: flex;
    justify-content: center;
}

.fraud-image{
    object-fit: cover;
    filter: brightness(30%);
}

.site-title,.contact-title{
    font-weight: bolder;
    font-size: larger;
}

.footer-last{
    display: flex;
    flex-direction: row;
    color:rgb(255,255,255);
    font-size:16px;
    padding: 20px;
    justify-content: space-between;
}

.mobile-footer-last{
    display: flex;
    flex-direction: row;
    color:rgb(255,255,255);
    font-size:14px;
    padding: 8px;
    justify-content: space-between;
}

#mobile-title {
    font-size:26px;
    font-weight: bolder;
    color: white;
}

.mobile-operation{
    width:100%;
    margin-top: 90px;
    color:rgb(255,255,255);
    font-size: small;
    padding: 20px;
}

.dSectionmobileFrau{
    position: relative;
    height: 355px;
}
 
.mobile-fraudSection{
    position: relative;
    display: flex;
    justify-content: center;
    height:300px ;
}

.site-link{
    color:white;
    font-size: 16px;
}

.site-link:hover,.mobile-site-link:hover{
   color: #458E33;
}

.mobile-site-link{
   color:white;
   font-size: 14px;
}
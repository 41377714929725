.fr-title{
    font-size: 45px;
    font-weight: bold;
}


.fr-image{
    width: 100%;
    max-width: 450px;
    height: 300px;
}

.pdf-res-titles{
    font-size: 18px;
    font-weight: bold;
}

.fr-pub-res{
    position: absolute;
    top: 200px;
    left: 40px;
    color: white;
    font-size: 45px;
}

.pdf-res-image{
    width: 100%;
    max-width: 350px;
    height: 250px;
}

.pdf-res-image-large{
    width: 100%;
    max-width: 450px;
    height: 300px;
}

.pdf-res-image-medium{
    width: 100%;
    max-width: 450px;
    height: 300px;
}

.ft-title-right{
    font-weight: bold;
    font-size: 20px;
    margin-top: 115px;
}

.messages-div{
    margin: 25px 0 0 25px; 
    align-items: center;
    justify-content: center
}

.ft-title-right-medium{
    font-weight: bold;
    font-size: 20px;
    margin-top: 100px;
}

.fr-pub-res-mobile{
    position: absolute;
    top: 150px;
    left: 40px;
    color: white;
    font-size: 26px;
}

.ft-title-right-mobile{
    font-weight: bold;
    font-size: 15px;
    margin-top: 50px;
}
.fr-down-btn{
    background-color: #ea9b0a;
    font-weight: bold;
}

.arrow-previous{
    color:#ea9b0a;
    cursor: pointer;
}

.important-messages-section{
    padding: 15px;
}

#message-text-title{
    font-weight: bold;
    
}

.view-all-button{
    color: white;
    background-color: rgb(35,31,32);
    border: none;
    border-color: transparent;
    height: 40px;
    width: 150px;
    font-weight: bold;
    cursor: pointer;
}

.arrow-forward{
    color:#ea9b0a;
    cursor: pointer;
}

.fr-travel-title{
    font-size: 40px;
    font-weight: bold;
    margin-top: 15px;
    margin-left:30px ;
}

.fr-travel-title-mobile{
    font-size: 26px;
    font-weight: bold;
    margin-top: 15px;
    margin-left:30px ;
}

/* extra small screen */
@media only screen and (max-width: 576px){
    
    .fr-image{
        width: 100%;
        max-width: 500px;
        height: 250px;
    }  
    .pdf-res-image{
        width: 100%;
        max-width: 500px;
        height: 300px;
        object-fit:scale-down;
    } 
}

/* small screen */
@media only screen and (min-width: 576px){
    
    .fr-image{
        width: 100%;
        max-width: 500px;
        height: 300px;
    }  
    
    .pdf-res-image{
        width: 100%;
        max-width: 500px;
        height: 300px;
        object-fit:scale-down;
    }   
}

/*  medium screen */
@media only screen and (min-width: 768px){
    
    .fr-image{
        width: 100%;
        max-width: 450px;
        height: 300px;
    }  

    .pdf-res-image{
        width: 100%;
        max-width: 600px;
        height: 450px;
        object-fit:scale-down;
    }
}

/*  large screen */
@media only screen and (min-width: 992px){
    
    .fr-image {
        width: 100%;
        max-width: 450px;
        height: 300px;
    }

    .pdf-res-image{
        width: 100%;
        max-width: 600px;
        height: 450px;
        object-fit:scale-down;
    } 
}

/*  xtra large screen */
@media only screen and (min-width: 1200px){
    
    .fr-image{
        width: 100%;
        max-width: 450px;
        height: 300px;
    }

    .pdf-res-image{
        width: 100%;
        max-width: 350px;
        height: 200px;
        object-fit:scale-down;
    } 
}

/*  xtra xtra  large screen */
@media only screen and (min-width: 1600px){

    .fr-image{
        width: 100%;
        max-width: 450px;
        height: 300px;
    }

    .pdf-res-image{
        width: 100%;
        max-width: 450px;
        height: 300px;
        object-fit:scale-down;
    } 
}

.Card{
    display: flex;
    align-items: center;
    text-align: center;
}






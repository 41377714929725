
.row1{
    display: flex;
    align-items: center;
    position:relative;
    background-color:  #D1D3D4;
    width: 100%; 
    height:800px;    
}

.header-text{
    position: absolute;
    top:5%;
    left: 5%;
    width: 60%;
}
  

.header-image{
    position: absolute;
    top:1%;
    left: 50%;
    text-align: center;
}

.header-bg-image{
    object-fit: cover;
    filter: brightness(1);
    height: 700px;
    object-fit:scale-down;
}

.header-text1{
    color:black;
    font-size: 105px;
    font-weight: bold;
    line-height: 250px;
}

.header-text2{
    color:black;
    font-size: 70px;
    font-weight: bold;
}

.header-text3{
    font-size: 35px;
    font-weight: 400;
}

.row2{
    height: 680px;
    position: relative;
}

.form-section{
    background-color: #62ACDF;
    position: absolute;
    padding: 5%;
    width:46%;
    height: 100%;
    top:0px;
    left:0px;
}

.how-to-use{
    background-color: white;
    position: absolute;
    top:0px;
    right:0px;
    padding: 2%;
    width:54%;
    height: 100%;
}

.row3{
    background-color: #0075B2;
    position: relative;
    height: 500px;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
}

.row1-mobile{
    display: flex;
    justify-content: center;
    flex-direction: column;
    position:relative;
    background-color:  #D1D3D4;
    margin-top:20px;
}
.header-mobile-image{
    text-align: center;
}

.form-mobile{
    position:relative;
    text-align:center;
    height:fit-content;
    width:100%;
    padding:50px;
    font-size: 14px;
    background-color: #62ACDF;
    color: white;
}

.row2-mobile{
    position:relative;
    text-align:center;
    height:fit-content;
    width:100%;
    padding:50px;
    overflow-x: auto;
    font-size: 14px;
}

.header-mobile-text{
    padding-left:5%;
}

.header-mobile-text1{
    color:black; 
    font-size: 65px;
    font-weight: bold;
}


.header-mobile-text2{
    color:black;
    font-size:35px;
    font-weight: bold;
}

.header-mobile-text3{
    font-size: 24px;
    font-weight: bold;
}

.row3-mobile{
    padding:5%;
    background-color: #0075B2;
    text-align: center;
}

.swal2-popup .swal2-styled:focus {
    box-shadow: none !important;
}

.request-btn{
    position: absolute;
    left: 40%;
}
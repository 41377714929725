/* large screens */
@media(min-width:960px)
{
        .contact-us-main-container{
        position: relative;
        background-image: url('/src/images/child-fl4.png');
        background-repeat: no-repeat;
        background-size:cover;
        padding: 80px 100px 80px 120px ;
        object-fit: cover;
        background-position: 0 -380px;
        height:650px;
        filter: brightness(95%);
        overflow: hidden;
        background-position:center;
        border: none;
        border-image: 0px;
    }

    .bg-image {
        object-fit: cover;
        filter: brightness(60%);
    }

    .form-div {
        position:absolute;
        padding: 50px;
        background-color: white ;
        height: 450px;
        top:120px;
        left: 48%;
        width: 48%;
        filter: brightness(100%);
    }

    form {
        justify-content:center ;
    }

    #contact-input,#contact-input:focus{
        background-color:#E0E1E2;
        font-size: 16;
        width: 400px;
        border-radius: 0px;
        border: none;
        border-color: transparent;
        padding: 5px;
        font-weight: bolder;
    }

    #select,#select:focus{
        background-color:#E0E1E2;
        font-size: 16;
        width: 400px;
        border-radius: 0px;
        border: none;
        border-color: transparent;
        height: 30px;
        padding: 5px;
        font-weight: bolder;
    }

    #text-area,#text-area:focus{
        background-color:#E0E1E2;
        font-size: 16;
        width: 400px;
        border-radius: 0px;
        border: none;
        border-color: transparent;
        height: 150px;
        padding:5px;
        font-weight: bolder;
    }

    #button {
        background-color:rgb(225,129,23);
        border: none;
        border-color: transparent;
        height: 40px;
        width: 100px;
        cursor: pointer;
        font-size: 16px;
    }

    .contact-us-div {
        width:38%;
        height: 450px;
        position:absolute;
        top:120px;
        left:10%;
        background-color: rgb(225,129,23);
        font-size:20px;
        padding-right:50%;
        opacity: 0.9; 
        display: flex;
        justify-content: center;
    }

    #contact-us-title {
        position:absolute;
        font-size: 40px;
        font-weight: bold;
        margin-top: 40px;
        color: black;
        top:10px;
        left:15%;
        margin-left:2%;
    }

    .icons-div{
        position: absolute;
        top:120px;
        left:5%;
        margin-left:2%;
    }

    .icons-div1 {
        position: absolute;
        top:180px;
        left:5%;
        margin-left:2%;
    }
}

/* smaller screens */

@media(max-width:959px){
        .contact-us-container{
        padding: 20px;
        width: 100%;
        height:300px;
        position: relative;
    }

    #contact-us-title{
        position:absolute;
        font-size: 26px;
        font-weight: bold;
        color: black;
        top:6px;
        left: 45%;
    }

    .icons-div{
        position: absolute;
        top:80px;
        /* left:20%;  */
        
        font-size: 18px;
        display: flex;
        justify-content: center;
        margin-left: 2%;
    }

    .icons-div1{
        position: absolute;
        
        font-size: 18px;
        top:150px;
        /* left:50%; */
        display: flex;
        justify-content: center;
        margin-left: 4%;
    }

    .form-div{
        padding: 10px;
        display: flex;
        justify-content: center;
    }

    form {
        justify-content:center ;
        width: 100%;
    }

    #select,#select:focus{
        background-color:#E0E1E2;
        
        font-size: 14;
        width: 100%;
        border-radius: 0px;
        border: none;
        border-color: transparent;
        height: 50px;
        padding: 5px;
    }

    #text-area,#text-area:focus{
        background-color:#E0E1E2;
        font-size: 14;
        font-weight: bold;
        width: 100%;
        border-radius: 0px;
        border: none;
        border-color: transparent;
        height: 80px;
        padding:5px;
    }

    #contact-input,#contact-input:focus{
        background-color:#E0E1E2;
        
        font-size: 14;
        border-radius: 0px;
        border: none;
        border-color: transparent;
        padding: 5px;
        width:100%;
    }

    #button {
        background-color:rgb(225,129,23);
        border: none;
        border-color: transparent;
        height: 40px;
        width: 100px;
        cursor: pointer;
        font-size: 16px;
    }

    .contact-us-div{
        position: relative;
        width: 100%;
        height: 300px;
        background-color: rgb(225,129,23);
        font-size: small;
        display: flex;
        justify-content: center;
    }

    .form-div{
        position: relative;
        width: 100%;
    }
}

/* //applies to all */
    ::-webkit-input-placeholder{
    color:#605E5F;
 }

.contact-txt{
    padding: 10px;
}
 
.contact-icons{
    width: 50px;
    height: 50px;
    margin-right:5px;
}

#selsect option {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    border: 1px solid #dfdfdf;
    border-radius: 2px;
    margin-right: 10px;
    padding: 1rem;
    padding-right: 2rem;
}

/* bigger screens */
@media (min-width:1372px) {

    .contact-us-div{
        width: 45%;
        text-align: center;
        display: flex;
        align-content: center;
        margin-left:5%;
    }

    .form-div {
        width: 50%;
        padding-left:5%;
    }

    #contact-us-title{
        position:absolute;
        font-size: 45px;
        font-weight: bold;
        color: black;
        padding-left:5%;
        top:2px;
        left:10%;
    }

    .icons-div{
        position: absolute;
        top:150px;
        left:5%;
        font-size: 24px;
    }

    .icons-div1{
        position: absolute;
        font-size: 24px;
        top:250px;
        left:5%;
    }

    .contact-icons{
        width: 80px;
        height: 80px;
        
    }

    #select,#select:focus{
        background-color:#E0E1E2;
        font-size: 14;
        width: 100%;
        border-radius: 0px;
        border: none;
        border-color: transparent;
        height: 50px;
        padding: 5px;
    }

    #text-area,#text-area:focus{
        background-color:#E0E1E2;
        font-size: 14;
        font-weight: bold;
        width: 100%;
        border-radius: 0px;
        border: none;
        border-color: transparent;
        height: 80px;
        padding:5px;
    }

    #contact-input,#contact-input:focus{
        background-color:#E0E1E2;
        font-size: 14;
        border-radius: 0px;
        border: none;
        border-color: transparent;
        padding: 5px;
        width:100%;
    }

    #button{
        background-color:rgb(225,129,23);
        border: none;
        border-color: transparent;
        height: 50px;
        width: 100px;
        cursor: pointer;
    }
}


.main-container{
    padding: 50px;
}

.Card{
    display: flex;
    align-items: center;
}

.gal-div{
    text-align: center;
}

.album-name{
    font-weight: bold;
}

.gallery-view{
    background-color:white;
    /* margin-bottom: 10px; */
    padding:1%;
}

.gallery-image{
    object-fit: cover;
    height: 95%;
    width: 95%;
    cursor: pointer;
   
}

.col-image{
    margin-bottom:5px;
    
}

.img-desc{
    position: absolute;
    padding: 10px;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

@media (max-width:600px) {

   .gallery-row {
        
        overflow-y: auto;
       
    }

    .gallerys-container{
        height:400px;
        overflow-y: auto;
       
    }

    .modal-image{
        height:200px;
    }

    .title-gallery{
        font-size: 26px;
        font-weight: bolder;
    }

    .Card{
        display: flex;
        align-items: center;
        text-align: center;
    }
}

@media  (min-width:601px) {
  
    .gallery-row{
        height:650px;
        overflow-y: auto;
        scrollbar-width: thin;
    }

    .gallerys-container{
        height:650px;
        overflow-y: auto;
        scrollbar-width: thin;
    }

    .title-gallery{
        font-size: 60px;
        font-weight: bolder;
    }
}
.main-div{
    padding: 50px;
}

.mes-header{
    font-size: 40px;
    font-weight: bold;
}

.mes-date{
    font-style: italic;
    font-weight: bold;
    font-size: 26px;
}

.mes-content{
    font-size: 16px;
}

@media only screen and (max-width: 600px){
    
    .mes-header{
        font-size: 26px;
        font-weight: bold;
    }
    
    .mes-date{
        font-style: italic;
        font-weight: bold;
        font-size: 18px;
    }
    
    .mes-content{
        font-size: 14px;
    }

    .main-div{
        padding: 50px;
    }
    
}

.main-div {
    display: flex;
    align-content: center;
    padding-top:5% 10% 10% 10%;
}

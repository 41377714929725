.case-status{
    height: 850px;
    position: relative;
}

.mobile-case-status{
    position: relative;
    height: 550px;
}

.bg-image{
    object-fit: cover;
    filter: brightness(60%);
}

.status{
    position: absolute; 
    top: 60px;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 40px;
    color: white;
}

#header{
    font-size: 40px;
    font-weight: bolder;
    
}

.status-display{
    padding: 20px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 400px;
    width: 80%;
    display: block;
    border-radius: 1px;
}
.status-head{
    background-color:rgb(16,77,132) ;
    text-align: center;
    color:rgb(255,255,255);
    font-size: 16px;
    padding: 10px;
    font-weight: bolder;
}

.status-body{
    padding-left: 100px;
    padding-top: 10px;
    padding-bottom: 30px;
    background-color: rgb(255,255,255);
}

.case-info{
    display: flex;
    flex-direction: row;
    
}

.mobile-status{
    position: absolute; 
    text-align: center;
    display: block;
    width: 100%;
    font-weight: bold;
    font-size: 26px;
    color:black;
    margin-bottom: 20px;
}

#mobile-header{
    font-size: 26px;
    font-weight: bolder;
}

.mobile-status-display{
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 300px;
    width: 100%;
    display: block;
    border-radius: 1px;
}

.mobile-status-head {
    background-color:rgb(16,77,132) ;
    text-align: center;
    color:rgb(255,255,255);
    font-size: 14px;
    padding: 10px;
}

.mobile-status-body{
    padding:10px 10px 10px 50px;
    background-color: rgb(255,255,255);
}

.mobile-case-info{
    display: flex;
    flex-direction: row;
    padding: 5px;
}

.mobile-right-value{
    font-size: 14px;
    color: black;
}

.mobile-label {
    font-size: 14px;
    font-weight: bolder;
    display: block;
    width: 200px;
    margin-left: 0px;
    text-align: justify;
}

.right-value{
    font-size: 16px;
    color: black;
}

.label {
    margin-right: 50px;
    font-size: 16px;
    font-weight: bolder;
    display: block;
    width: 200px;
    margin-left: 0px;
    text-align: justify;
}

.col-items{
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.mobile-account-details{
    padding: 10px 0px 20px 50px;
}

.help-case{
    padding: 80px;
}

#help-title{
    font-weight: bolder;
    margin-top: 30px;
    font-size: 40px;
    text-align: center;
    
}

#mobile-help-title{
    font-weight: bolder;
    margin-top: 15px;
    font-size: 26px;
    text-align: center;
    
}

#mobile-contacts-title {
    font-weight: bolder;
    font-size: 26px;
    text-align: center;
}

.help-steps {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 30px;
    font-size: small;
}

#info-title {
    font-weight: bolder;
    display: inline-block;
    font-size: 16px;
}

.contacts {
    background-color: rgb(225,129,23);
    padding: 28px;
    display: block;
    text-align: center;
}

#contacts-title {
    font-weight: bolder;
    font-size: 26px;
}

.account-details {
    padding: 10px 0px 20px 100px;
}

.details-title{
    font-weight: bolder;
    font-size: 40px;
}

.user-info {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    padding-bottom: 20px; 
}

.col-itmes-div {
    height: 200px;
    display: block;
    margin-top: 10px;
}

#contacts-p {
    font-size: 16px;
}

#contacts-p-mobile {
    font-size: 14px;
}

input[type=text],input[type=text]:focus,input[type=file],input[type=file]:focus {
    border: none;
    background-color: transparent;
    width: 300px;
    outline: none;
}

 
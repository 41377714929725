
body, p, .header-text,
.haeder-text1, .header-text2,.header-text3,.header-mobile-text,.header-mobile-text1,
.header-mobile-text2,.header-mobile-text3,.row3,.row3-mobile,.form-aection,
h1, h2, h3, h4, h5, h6, span, div, .card, .card-body, button, input
{
    font-family: 'Lato', sans-serif !important;
}

body {
    margin:0;
    background-color: #F6F6F6;
    overflow: scroll;
    font-weight: 400;
}

code {
    font-weight: 400;
}
.not-container{
    display:flex ;
    flex-direction: row;
    justify-content: center;
    padding: 100px;
}

.aside{
    width: 600px;
}

.home-btn{
    background-color: #E38C24;
    color: black;
    cursor: pointer;
}
main{
    font-weight: bold;
}
.video{
    max-width: 450px;
    height: 350px;
    padding: 10px;
    object-fit: cover;
    position: relative;
}

.playBtn{
    color: #f93b5c;
    font-size: 84px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media only screen and (max-width: 600px){
    .video{
        max-width: 225px;
        height: 150px;
        padding: 10px;
        object-fit: cover;
        position: relative;
    }
    
    .playBtn{
        color: #f93b5c;
        font-size: 42px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
}

.important-messages-section{
    padding: 15px;
}

#message-text-title{
    font-weight: bold;
    
}

.view-all-button{
    color: white;
    background-color: rgb(35,31,32);
    border: none;
    border-color: transparent;
    height: 40px;
    width: 150px;
    cursor: pointer;
}

.messages-div{
    display: flex;
    flex-direction: row;
}

.card {
    border-radius:2px;
    border-color: #B6B6B5;
    width: 100%;
    padding: 10px;
    height: 450px;
}

.card-title{
    font-weight: bolder;
    font-size: 18px;
    width: 100%;
    display: block;
    max-height: 120px;
    overflow: hidden;
}

.card-date{
    font-weight: bold;
    font-style: italic;
}

.card-body{
    width: 100%;
    display: block;
    max-height: 180px;
    overflow: hidden;
}

.read-more-button{
    background-color: rgb(223,143,47);
    border: none;
    border-color: transparent;
    height: 40px;
    width: 200px;
    cursor: pointer;
}

.view-more-div{
    display: flex;
    justify-content: center;
    padding: 20px;
}
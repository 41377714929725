.imgPos{
    max-width: 450px;
    height: 350px;
    padding: 10px;
    object-fit: cover;
    position: relative;
    filter: brightness(50%);
}

.pub{
    color: white;
    font-size: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight:bold;
}

@media only screen and (max-width: 600px){
    .imgPos{
        max-width: 225px;
        height: 150px;
        padding: 10px;
        position: relative;
        filter: brightness(50%);
    }
    
    .pub{
        color: white;
        font-size: 20px;
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight:bold;
    }
    
}
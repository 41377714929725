.map-area{
    background-color: rgb(230,231,233);
    padding: 40px;
}

@media (min-width:1016px) {
    
    .map-div{
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        background-color: rgb(230,231,233);
        padding: 40px;
        width: 80%;
    }

    .map{
        height: 450px;
        width: 450px;
    }

    .map-label{
        font-weight: bolder;
        padding-top: 300px;
    }

    .map-tooltip{
        text-align: center;
        padding:5px;
        background-color: rgb(227,140,36);
        height:fit-content;
        display: block;
        position: absolute;
        align-self: center;
        width: fit-content;
        left:50%;   
        font-weight: bolder;
    }
}

@media (max-width:1015px) {
    
    .map-div{
        display: flex;
        flex-direction:column;
        justify-content: center;
        background-color: rgb(230,231,233);
        width:100%;
        padding-left: 25%;
    }

    .map{
        height:80%;
        width: 100%;
    }

    .map-tooltip{
        text-align: center;
        padding:2px;
        background-color: rgb(227,140,36);
        height:fit-content;
        display: block;
        position: absolute;
        align-self: center;
        width: fit-content;
        left:5%;
        font-size: 14px;
        font-weight: bolder;
    }
}

.map-title{
    text-align: center;
    font-weight: bolder;
    font-size: 28px;
}

.label1{
    display: block;
    background-color:blue;
    width: 70px;
    height: 30px;
    margin-right: 5px;
}

.label2{
    display: block;
    background-color:#1C7EF4;
    width: 70px;
    height: 30px;
    margin-right: 5px;
}

.label-div{
    display: flex;
    flex-direction: row;
    margin: 5px;
}